define("bandsite3/components/show-voorbeeld", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <article class="rental">
    <Show::Image
      src={{@show.image}}
      alt="A picture of {{@show.title}}"
    />
    <div class="details">
      <h3>{{@show.title}}</h3>
      <div class="detail owner">
        <span>Owner:</span> {{@show.owner}}
      </div>
      <div class="detail type">
        <span>Type:</span> {{@show.type}}
      </div>
      <div class="detail location">
        <span>Location:</span> {{@show.city}}
      </div>
      <div class="detail bedrooms">
        <span>Number of bedrooms:</span> {{@show.bedrooms}}
      </div>
    </div>
    <Map
      @lat={{@show.location.lat}}
      @lng={{@show.location.lng}}
      @zoom="9"
      @width="150"
      @height="150"
      alt=A map of {{@show.title}}
    />
  </article>
  */
  {
    "id": "pm+K9kg+",
    "block": "[[[10,\"article\"],[14,0,\"rental\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,\"src\",[30,1,[\"image\"]]],[16,\"alt\",[29,[\"A picture of \",[30,1,[\"title\"]]]]]],null,null],[1,\"\\n  \"],[10,0],[14,0,\"details\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"detail owner\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"Owner:\"],[13],[1,\" \"],[1,[30,1,[\"owner\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"detail type\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"Type:\"],[13],[1,\" \"],[1,[30,1,[\"type\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"detail location\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"Location:\"],[13],[1,\" \"],[1,[30,1,[\"city\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"detail bedrooms\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"Number of bedrooms:\"],[13],[1,\" \"],[1,[30,1,[\"bedrooms\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,\"alt\",\"A\"],[24,\"map\",\"\"],[24,\"of\",\"\"],[4,[30,1,[\"title\"]],null,null]],[[\"@lat\",\"@lng\",\"@zoom\",\"@width\",\"@height\"],[[30,1,[\"location\",\"lat\"]],[30,1,[\"location\",\"lng\"]],\"9\",\"150\",\"150\"]],null],[1,\"\\n\"],[13]],[\"@show\"],false,[\"show/image\",\"map\"]]",
    "moduleName": "bandsite3/components/show-voorbeeld.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});