define("bandsite3/templates/ponykamp", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vl1wcBGH",
    "block": "[[[1,\"'t is heer gèn ponykamp\"]],[],false,[]]",
    "moduleName": "bandsite3/templates/ponykamp.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});