define("bandsite3/components/show", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @show.ticketlink}}
    <a class="showlink" href="{{@show.ticketlink}}" target="_blank" rel="noopener noreferrer">
      <div class="show row" title="{{@show.ticketlink}}">
        <div class="showfield cell">{{@show.date}}</div>
        <div class="showfield cell">{{@show.venue}}</div>
        {{#if @show.country}}
          <div class="showfield cell">{{@show.city}} ({{@show.country}})</div>
        {{else}}
          <div class="showfield cell">{{@show.city}}</div>
        {{/if}}
      </div>
    </a>
  {{else}}
    <div class="show row">
      <div class="showfield cell">{{@show.date}}</div>
      <div class="showfield cell">{{@show.venue}}</div>
      {{#if @show.country}}
        <div class="showfield cell">{{@show.city}} ({{@show.country}})</div>
      {{else}}
        <div class="showfield cell">{{@show.city}}</div>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "o7bvGK24",
    "block": "[[[41,[30,1,[\"ticketlink\"]],[[[1,\"  \"],[10,3],[14,0,\"showlink\"],[15,6,[29,[[30,1,[\"ticketlink\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"show row\"],[15,\"title\",[29,[[30,1,[\"ticketlink\"]]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"date\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"venue\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"country\"]],[[[1,\"        \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"city\"]]],[1,\" (\"],[1,[30,1,[\"country\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"city\"]]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"show row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"date\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"venue\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"country\"]],[[[1,\"      \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"city\"]]],[1,\" (\"],[1,[30,1,[\"country\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"showfield cell\"],[12],[1,[30,1,[\"city\"]]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"@show\"],false,[\"if\"]]",
    "moduleName": "bandsite3/components/show.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});