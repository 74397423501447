define("bandsite3/routes/listen", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ListenRoute extends _route.default {
    async model() {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      return fetch('https://api.afterpartees.com?call=spotify', requestOptions).then(response => response.json()).then(result => {
        return result;
      }).catch(error => {
        console.log('fout' + error);
      });
    }

  }

  _exports.default = ListenRoute;
});