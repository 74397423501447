define("bandsite3/components/spotifyframe", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="spotifyframe">
    <h3>{{@title}}</h3>
    <iframe src="{{@url}}" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
  </div>
  */
  {
    "id": "Dk+zpoPx",
    "block": "[[[10,0],[14,0,\"spotifyframe\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[10,\"iframe\"],[15,\"src\",[29,[[30,2]]]],[14,\"width\",\"100%\"],[14,\"height\",\"380\"],[14,\"frameBorder\",\"0\"],[14,\"allowtransparency\",\"true\"],[14,\"allow\",\"encrypted-media\"],[12],[13],[1,\"\\n\"],[13]],[\"@title\",\"@url\"],false,[]]",
    "moduleName": "bandsite3/components/spotifyframe.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});