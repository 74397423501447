define("bandsite3/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OUUT3c3X",
    "block": "[[[10,0],[14,0,\"wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,null],[1,\"\\n    \"],[10,0],[14,0,\"outlet\"],[12],[1,\"\\n      \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"nav-bar\",\"component\",\"-outlet\",\"footer\"]]",
    "moduleName": "bandsite3/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});