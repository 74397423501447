define("bandsite3/components/map", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "bandsite3/config/environment"], function (_exports, _component, _templateFactory, _component2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="map">
    <img
      alt="Map image at coordinates {{@lat}},{{@lng}}"
      ...attributes
      src={{this.src}}
    width={{@width}} height={{@height}}
    >
  </div>
  */
  {
    "id": "D+vifOHw",
    "block": "[[[10,0],[14,0,\"map\"],[12],[1,\"\\n  \"],[11,\"img\"],[16,\"alt\",[29,[\"Map image at coordinates \",[30,1],\",\",[30,2]]]],[17,3],[16,\"src\",[30,0,[\"src\"]]],[16,\"width\",[30,4]],[16,\"height\",[30,5]],[12],[13],[1,\"\\n\"],[13]],[\"@lat\",\"@lng\",\"&attrs\",\"@width\",\"@height\"],false,[]]",
    "moduleName": "bandsite3/components/map.hbs",
    "isStrictMode": false
  });

  const MAPBOX_API = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static';

  class MapComponent extends _component2.default {
    get src() {
      let {
        lng,
        lat,
        width,
        height,
        zoom
      } = this.args;
      let coordinates = `${lng},${lat},${zoom}`;
      let dimensions = `${width}x${height}`;
      let accessToken = `access_token=${this.token}`;
      return `${MAPBOX_API}/${coordinates}/${dimensions}@2x?${accessToken}`;
    }

    get token() {
      return encodeURIComponent(_environment.default.MAPBOX_ACCESS_TOKEN);
    }

  }

  _exports.default = MapComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapComponent);
});