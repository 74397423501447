define("bandsite3/templates/listen", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jB1Wq07T",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@title\",\"@url\"],[[29,[[30,2,[\"title\"]]]],[29,[[30,2,[\"url\"]]]]]],null],[1,\"\\n\"]],[2]],null]],[]],[[[1,\"    \"],[10,0],[14,0,\"error-message\"],[12],[1,\"\\n      Could not fetch Spotify info, the bass player is trying to fix it.\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[\"@model\",\"listen\"],false,[\"jumbo\",\"if\",\"each\",\"-track-array\",\"spotifyframe\"]]",
    "moduleName": "bandsite3/templates/listen.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});