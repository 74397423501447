define("bandsite3/templates/info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "nkcAG/R/",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[3,\"  HTML wordt standaard ge-escaped in handlebars, dit is uit te schakelen\\n      door 3 accolade's te gebruiken \"],[1,\"\\n  \"],[10,0],[14,0,\"biography\"],[12],[1,\"\\n    \"],[2,[30,1,[\"biography\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"contacts\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"contact\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"contact\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2,[\"content\"]]],null]],null],null,[[[1,\"          \"],[1,[30,3]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\",\"contact\",\"content\"],false,[\"jumbo\",\"each\",\"-track-array\"]]",
    "moduleName": "bandsite3/templates/info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});