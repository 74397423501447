define("bandsite3/templates/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wdrUgB7I",
    "block": "[[[10,\"img\"],[14,\"alt\",\"Schonne kels\"],[14,\"title\",\"Schônne kèls\"],[14,\"width\",\"80%\"],[14,\"src\",\"assets/images/band_higher_places.png\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"row socials\"],[12],[1,\"\\n  \"],[10,3],[14,0,\"cell social\"],[14,\"target\",\"_blank\"],[14,6,\"https://www.facebook.com/afterpartees/\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fab fa-facebook fa-3x\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,3],[14,0,\"cell social\"],[14,\"target\",\"_blank\"],[14,6,\"https://www.instagram.com/afterpartees/\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fab fa-instagram fa-3x\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,3],[14,0,\"cell social\"],[14,\"target\",\"_blank\"],[14,6,\"https://bsky.app/profile/afterpartees.bsky.social\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fab fa-bluesky fa-3x\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,3],[14,0,\"cell social\"],[14,\"target\",\"_blank\"],[14,6,\"https://open.spotify.com/artist/5FtnkdycRhdTikTa8XaM02\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"fab fa-spotify fa-3x\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "bandsite3/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});