define("bandsite3/components/nav-bar", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav class="menu">
    <div class="navigationbar">
      <div class="navbar-logo">
        <LinkTo @route="index">
          <img src="/assets/images/logo.svg" width="50%" title="Oh waat moei" alt="A F T E R P A R T E E S">
        </LinkTo>
      </div>
      <div class="links">
        <LinkTo @route="shows" class="navitem">
          Shows
        </LinkTo>
          <a class="navitem" target="_blank" href="https://afterpartees.company.site/" rel="noopener noreferrer">
            Shop
          </a>
        <LinkTo @route="listen" class="navitem">
          Listen
        </LinkTo>
        <LinkTo @route="info" class="navitem">
          Info
        </LinkTo>
      </div>
    </div>
  </nav>
  */
  {
    "id": "droJEPAN",
    "block": "[[[10,\"nav\"],[14,0,\"menu\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"navigationbar\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"navbar-logo\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/logo.svg\"],[14,\"width\",\"50%\"],[14,\"title\",\"Oh waat moei\"],[14,\"alt\",\"A F T E R P A R T E E S\"],[12],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"links\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"navitem\"]],[[\"@route\"],[\"shows\"]],[[\"default\"],[[[[1,\"\\n        Shows\\n      \"]],[]]]]],[1,\"\\n        \"],[10,3],[14,0,\"navitem\"],[14,\"target\",\"_blank\"],[14,6,\"https://afterpartees.company.site/\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n          Shop\\n        \"],[13],[1,\"\\n      \"],[8,[39,0],[[24,0,\"navitem\"]],[[\"@route\"],[\"listen\"]],[[\"default\"],[[[[1,\"\\n        Listen\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,0],[[24,0,\"navitem\"]],[[\"@route\"],[\"info\"]],[[\"default\"],[[[[1,\"\\n        Info\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\"]]",
    "moduleName": "bandsite3/components/nav-bar.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});